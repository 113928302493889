$ada-deep-purle: #1a0034;
$ada-med-purple: #400080;
$ada-med-blue: #000080;
$ada-light-purple: #794ca6;

$title-font: 'Lexend Deca', sans-serif;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);