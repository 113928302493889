/*!
* e-net Kanzan Theme Stylesheet
* Copyright 2020 AdA Risques
* Licensed under MIT
*/
@import "variables";

body {
    font-size: .875rem;
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
}

a {
    color: $ada-light-purple;
}

.text-primary {
    color: $ada-light-purple !important;
}

.btn-primary {
    border-color: $ada-deep-purle;
    background-color: $ada-med-purple;
}
.btn-outline-primary {
    border-color: $ada-med-purple;
    color: $ada-med-purple;
}

h1, .h1,
h2, .h3,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    font-family: $title-font;
}

.app-name {
    font-family: 'Lexend Deca', sans-serif;
    letter-spacing: .25ex;
}

@import "auth";
@import "navbar";
@import "sidebar";
@import "events";
@import "alumni";

/*
* Content
*/

@media (min-width: 992px) {
    [role="main"] {
        padding-top: 48px; /* Space for fixed navbar */
    }
}
