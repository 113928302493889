/*
* Navbar
*/

.navbar {
    font-family: $title-font;

    .form-control {
        padding: .75rem 1rem;
        border-width: 0;
        border-radius: 0;
    }

    .nav-link {
        padding-left: .5em;
        padding-right: .5em;

        .fab {
            font-size: 1.75em;
        }
    }
}

.navbar-dark {
    /*background: linear-gradient(to right, #272c30 10%, #343a40 20%);*/
    background: linear-gradient(to right, $ada-deep-purle, #27004e, $ada-med-blue);
}

.navbar-brand {
    padding: .75rem .25em .75em .75em;
    font-size: 1rem;
    flex-grow: 1;
    /*box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);*/
}

.navbar-toggler {
    top: .25rem;
    right: 1rem;
    margin-right: 0.5em;
}

.form-control-dark {
    color: #fff;
    background-color: rgba(white, .1);
    border-color: rgba(white, .1);

    &:focus {
        border-color: transparent;
        box-shadow: 0 0 0 3px rgba(white, .25);
    }
}

.navbar-user {
    white-space: nowrap;
    padding-left: .5em;
    padding-right: .5em;
}